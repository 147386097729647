<!doctype html>
<html>
<head>
<meta charset="UTF-8">
<title>DéZ’Accordés</title>
<!-- FONT -->
<link href="https://fonts.googleapis.com/css?family=Raleway&display=swap" rel="stylesheet">
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css">

<!-- <link href="style.css" rel="stylesheet" type="text/css"> -->
<!-- BOOTSTRAP -->
</head>

<body>
	<!-- Navigation -->

	<nav class="navbar navbar-expand-lg navbar-light bg-light">
		<div class="container">
			<a class="navbar-brand" href="">
				<img src="assets/images/logo.png" height="70px" alt="">
			</a>
			<ul class="navbar-nav">
				<li class="nav-item">
					<a class="nav-link" href="#group">Groupe</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="#listen">Nous écouter</a>
				</li>
				<li class="nav-item">
					<a class="nav-link disabled" href="#shows">Nos dates</a>
				</li>
				<li class="nav-item">
					<a class="nav-link disabled" href="#contact">Contacts</a>
				</li>
			</ul>
		</div>
	</nav>
	<section id="carouselDez" class="carousel slide" data-ride="carousel">
		<div class="carousel-inner">
			<ol class="carousel-indicators">
				<li data-target="#carouselDez" data-slide-to="0" class="active"></li>
				<li data-target="#carouselDez" data-slide-to="1"></li>
			</ol>
			<div class="carousel-item active">
				<img src="assets/images/photo1.jpg" class="d-block w-100" alt="...">
			</div>

			<div class="carousel-item">
				<img src="assets/images/photo2.jpg" class="d-block w-100" alt="...">
			</div>
		</div>
		<a class="carousel-control-prev" href="#carouselDez" role="button" data-slide="prev">
			<span class="carousel-control-prev-icon" aria-hidden="true"></span>
			<span class="sr-only">Previous</span>
		  </a>
		  <a class="carousel-control-next" href="#carouselDez" role="button" data-slide="next">
			<span class="carousel-control-next-icon" aria-hidden="true"></span>
			<span class="sr-only">Next</span>
		  </a>
	</section>
	<section id="group" class="section sc_light">
		<div class="container">
			<h2>Groupe DéZ’Accordés</h2>
			<p class="text-justify">Les DéZ’Accordés, c’est une histoire de familles, de folklore et d’amitiés. Corentin (à la vielle) et Mathilde (au violon) ont été baignés dès avant leurs naissances aux sonorités des musiques traditionnelles et populaires d’Auvergne et du Centre-France. Élise (à la vielle alto) aussi a grandi dans le folklore berrichon. Les hasards de la vie les a réunis tous les trois à la Roche-Posay dans le groupe de folklore des Baladins des Deux Eaux à l’été 2018. Rejoints par leur ami Valentin (à la guitare) à l’été 2019, ils créent ensemble le groupe folk des DéZ’Accordés. Tous les quatre sillonnent en chant et en musique les paysages sonores de France, d’Irlande, d’Espagne, des Pays de l’Est, mais aussi les univers de fictions, comme les contrées de leurs imaginations dans leurs créations. Un quatuor vitaminé composés d’accords, de cordes et d’éclats de rire !</p>
			<hr>
			<div class="row">
				<div class="col-sm-4">
					<h3>Mathilde PÉRIVIER</h3>
					<img src="assets/images/mp.jpg" width="100%">
				</div>
				<div class="col-sm-8 text-justify">
					<p>
						Méfiez-vous d’elle, surtout quand vous la voyez s’approcher de vous avec un grand sourire, toute guillerette et vous lancer : « j’ai eu une idée géniale, vous allez adorer ! ». Quand elle dit « génial », le reste de la troupe comprend « farfelu » ; quand elle annonce « vous allez adorer », ils se disent « mais qu’est-ce qu’elle a encore été chercher ! ». Car des DéZ’Accordés, Mathilde est passion. Depuis son plus jeune âge, elle se passionne et « elle adore ». Ses goûts sont très éclectiques : cela va de ses neveux et nièces, à avoir la fève dans la galette des Rois, mettre la musique à fond dans la voiture, voyager en train, préparer de la soupe, essayer de capter et reproduire tous les accents régionaux et francophones possibles et imaginables avec un succès encore incertain, aux mots et jurons désuets, au débat, à la grammaire, à la littérature, à l’écriture, sans oublier l’Amérique Latine, les Caraïbes et la rencontre avec les gens.
						Mais de toutes ses passions, la plus grande est de toute évidence la musique. La musique est son premier réflexe le matin, parfois même avant d’avoir mis ses lunettes sans lesquelles elle ne voit pourtant rien. Du coup, elle découvre souvent des trucs un peu chelou, mais généralement plus c’est chelou, plus elle aime. La musique est sa compagne de route la plus fidèle, la nuit comme le jour, ici et là-bas. C’est également son loisir et puis son objet d’étude... Car oui, Mathilde a choisi de faire de la musique l’objet de la thèse doctorale qu’elle rédige en anthropologie – la deuxième grande passion de sa vie. Vous l’avez deviné : comme la majorité de ses collègues en sciences sociales, elle est légèrement obsessionnelle, mais juste ce qu’il faut.
						Comme Corentin, Mathilde danse dans les Baladins des Deux Eaux depuis sa conception. Déterminée depuis son plus jeune âge à réaliser coûte que coûte tous ses rêves, elle prend ses premiers cours de violon à 5 ans avec Mathieu Kasolter. Elle entre ensuite à l’école de musique de Loches (37) où elle suit les cours d’Arnaud de Lespinay pendant quatre ans et elle réalise enfin son deuxième cycle de violon à l’école nationale de Châtellerault (86) avec Christiane Tessereau. À l’âge bête, soit à 14 ans, elle se dit que ce serait certainement plus fun de faire du poney plutôt que de la musique classique. Résultat : elle arrête les cours de violon, en revanche, elle ne commence jamais le poney.
						Mais puisqu’il n’y a que les imbéciles qui ne change pas d’avis, après avoir vécu en Espagne, en Colombie et en Haïti, elle revient à ses premières amours et se lance de nouveaux défis : jouer du folk au violon et chanter. Résumons : Mathilde aime les contre-temps, les rythmes qui sortent de l’ordinaire, les percussions instrumentales et corporelles, les dissonances, les polyphonies et les polyrythmies. Elle est un peu à l’image de tous ses voyages.
					</p>
				</div>
			</div>
			<hr>
			<div class="row">
				<div class="col-sm-8 text-justify">
					<p>
						Les Phénix existent. Ils se déguisent parfois en êtres humains. On les reconnaît car ils sont solaires, ne se laissent jamais abattre, renaissent toujours plus forts des affronts que la vie leur inflige et sont pleins de surprises. S’il est un Phénix chez les DéZ’Accordés, c’est bien Élise. De notre quatuor, c’est le rayon de soleil et l’encyclopédie vivante des musiques, des musiciennes et musiciens du mouvement trad en France.
						Élise est berrichonne, originaire du Pont-Chrétien (36), mais elle est sûrement née dans un pays méditerranéen dans une vie antérieure : elle en a toute la générosité. Comme les mamma italiennes, Élise nous materne et nous gâte, nous prépare toujours de bons petits plats, sans jamais cesser de nous faire découvrir beaucoup de choses avec une humilité sans pareil. Bon d’accord, on ne comprend pas toujours du premier coup ce qu’elle dit lorsqu’elle nous explique les théories de la musique, mais petit à petit, ça vient ; et il faut parfois s’accrocher pour la suivre car elle a aussi le débit de paroles d’une mamma. Personne ne peut égaler son record de vitesse pour prononcer la phrase suivante avec une diction parfaite : « la p’tite chaise à pépé ».
						Élise est tombée dans la marmite des arts et traditions populaires dès son plus jeune âge. Elle découvre le folklore berrichon dans le groupe des Tréteaux du Pont Vieux à Argenton. Elle commence la vielle à roue à l’âge de 10 ans et alors qu’elle a 16 ans, elle suit un cursus de vielle au Conservatoire de Châteauroux avec Jacky Aucouturier d’où elle sort diplômée d’un DEM en 2016. La vielle à roue n’est pas son seul talent, elle sait aussi jouer de la cornemuse du Centre – cornemuse qu’on compte bien entendre sonner prochainement dans les DéZ’Accordés, mais ça, elle ne lui a pas encore dit. Elle sillonne aussi la France avec la troupe de musique médiévale Baladins et Compagnie. Souvent, on se demande quand elle dort, et puis après on se souvient que les Phénix n’ont pas besoin de dormir.
						Son bac en poche, elle part à la conquête de la fac de musicologie de Tours, avec sa vielle sous le bras : non, Élise n’a pas peur de bouleverser les codes de la musique dite classique. La vie a fait que finalement elle a choisi une autre voie, et elle est aujourd’hui préparatrice en pharmacie. En gros, dans la vie de tous les jours, dans son métier comme dans ses passions musicales, elle partage son don de la sollicitude. Élise a le soin en elle et elle sait prendre soin.
					</p>
				</div>
				<div class="col-sm-4">
					<h3>Élise DUPONT</h3>
					<img src="assets/images/ed.jpg" width="100%">
				</div>
			</div>
			<hr>
			<div class="row">
				<div class="col-sm-4">
					<h3>Valentin JÉLODIN</h3>
					<img src="assets/images/vj.jpg" width="100%">
				</div>
				<div class="col-sm-8 text-justify">
					<p>
						Dernier arrivé dans les DéZ’Accordés, Valentin est pourtant aujourd’hui le ciment de notre quatuor, sa pièce maîtresse. Il est le sel qui manquait à notre miel. Jouer sans lui nous paraît désormais tout bonnement inconcevable. Et pourtant il a fallu le travailler au corps – attention, ne nous méprenons pas sur le sens de cette expression – pour enfin le décider à rejoindre l’aventure cordée. Car rien dans son parcours ne laissait présager qu’on le retrouverait dans un groupe de musique trad.
						Valentin est originaire du pays de la célèbre galette au fromage, à Martizay (36). Dans leur jeunesse, ses parents ont un peu pratiqué les danses trad berrichonnes puis se sont investis avec passion dans une troupe de théâtre à Néons-sur-Creuse. De son enfance au pied des planches, il garde les yeux rieurs, des mimiques hors du commun et une manière de nous charrier avec une désinvolture singulière qui nous fascine. Valentin maîtrise l’art d’intervenir au bon moment. Même lorsqu’il est sérieux, il nous fait marrer. En plus du talent de comédien qu’il travaille sans relâche depuis quelques années, Valentin est le sportif de la bande. Il a commencé le tennis à l’âge de 5 ans, et une fois son bac obtenu, il a étudié en fac de sport à Poitiers (86) avant de passer son diplôme d’État de tennis. Aujourd’hui, de peur de faire de l’ombre à Roger, Rafael et Novak, il se réinvente professionnellement et s’adonne pleinement à la musique.
						Car depuis longtemps, la musique occupe une place de choix dans sa vie. Quand il a 7 ans, il commence des cours de piano à Bossay-sur-Claise (37) puis au musicobus qui venait à Martizay. Il les suivra pendant 8 ans, puis apprend la guitare en autodidacte quand il est en terminale. Avec des copains, il fait alors partie d’un groupe de métal. La playlist de Valentin est vertigineuse : ça va du rap au métal, en passant par le rock anglais toutes générations, par le blues, le reggae et la nouvelle chanson française. Les accords qu’il nous propose donne à notre musique d’autres textures, et ça, on adore. Et ce qui est dingue aussi, c’est qu’il a une mémoire d’éléphant : du coup, il se souvient de plein de paroles de chansons, et même s’il n’ose pas les chanter tout fort, on voit pourtant ses lèvres bouger face à presque tout ce qu’il écoute.
						Les deux autres passions de Valentin, c’est le coca grenadine et tout ce qui est à base de patates et de fromage fondu. Et en fait, ça lui ressemble un peu ces plats montagnards. Dis-moi ce que tu manges et je te dirai qui tu es. Parfois taciturne, Valentin est quelqu’un de très tendre et généreux. Il est toujours là pour les gens qu’il aime, et comme la raclette, la tartiflette, et tous les autres plats qui se terminent en « -ette », plus on a goûté à son amitié et au charme de sa personnalité, moins on peut s’en passer.
					</p>
				</div>
			</div>
			<hr>
			<div class="row">
				<div class="col-sm-8 text-justify">
					<p>
						Des DéZ’Accordés, il est la fougue. Ne vous fiez pas à sa taille et sa barbe d’hipster : Corentin est le plus jeune d’entre nous. Il est tellement jeune que lorsque Élise aura quarante ans, il sera toujours dans sa vingtaine. Eh ouais, quand on y pense, ça nous met en peu le seum, mais on se console en gardant à l’esprit qu’avec les DéZ’Accordés, notre jeunesse sera évidemment éternelle. Quand il parle, Corentin utilise des mots anglais et des expressions qui nous, les trentenaires ou quasi-trentenaire, nous font rire : « de ouf », « au bout d’ma vie », etc. On essaie alors de mettre notre vocabulaire à la page, de rajeunir nos expressions, mais souvent ça tombe à côté, et alors là, c’est lui rigole.
						On adore taquiner notre Coco, mais sachez que s’il est jeune, il n’en a pas moins la tête bien vissée sur les épaules : heureusement, vous allez me dire ! Ce joli site internet, c’est lui ; le choix du design et du graphisme, c’est lui ; les initiatives de diffusion via les réseaux sociaux numériques, c’est lui. Il a toute notre confiance et on n’est jamais déçu. Car Corentin est étudiant dans une école d’ingénieur en informatique à Villejuif (94), après avoir étudié en licence à la Rochelle (17). Vous l’aurez compris, Corentin maîtrise plutôt bien le clavier. Mais pas uniquement celui de l’ordinateur. Jusqu’à ce qu’on rencontre Élise, on pensait que s’il était si doué en informatique, c’était grâce à la vielle. Maintenant, on sait que les deux ne sont pas nécessairement corrélés.
						Corentin dansait déjà dans le ventre de sa maman dans le groupe de folklore auvergnat les Baladins des Deux Eaux à la Roche-Posay (86). On ne pourrait pas dire avec exactitude à quel âge il a commencé à danser, car depuis petit, on l’y a toujours vu. Par contre, on peut affirmer avec certitude qu’il a commencé à apprendre la vielle à roue alors qu’il était âgé de 8 ans avec Jean-Claude Koffmann puis a continué en autodidacte. Corentin n’hésite pas à chercher des conseils et à apprendre. Quand il s’agit de la vielle, il ne se décourage jamais ; au contraire, il est chaque jour plus motivé. Il participe régulièrement à des stages et il adore rencontrer des musiciens, participer à des bœufs, tenter des trucs et improviser.
						Suivre son parcours nous permet de faire tomber bon nombre de préjugés autour des arts populaires : on peut à la fois faire partie de la génération Z, des digital natives, faire des études d’informatique, maîtriser « de ouf » Instagram, Facebook et tout l’toutim, et kiffé le folklore, les danses et les musiques trad. D’ailleurs, on en est sûrs, s’il existait un darknet du folk et du folklore, Corentin en serait un des fondateurs, car loin devant le fromage, le folk(lore), c’est vraiment sa came.
					</p>
				</div>
				<div class="col-sm-4">
					<h3>Corentin DRAULT</h3>
					<img src="assets/images/cd.jpg" width="100%">
				</div>
			</div>
		</div>
	</section>

	<section id="listen" class="section sc_light">
		<div class="container">
			<h2>Écoutez-nous !</h2>
			<p>Découvrez nos morceaux sur youtube !</p>
			<div class="embed-responsive embed-responsive-16by9">
				<iframe src="https://www.youtube.com/embed/A5Jzji4ha3w" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>
		</div>
	</section>

	<!--section id="shows" class="section sc_dark">
		<div class="container">
			<h2>Nos prochaines dates</h2>
			<ul>
					// $row = 1; $cpt = 0;
					// if (($handle = fopen("date.csv", "r")) !== FALSE) {
					// 	while (($data = fgetcsv($handle, 1000, ";")) !== FALSE) {
					// 		$num = count($data); $row++;

					// 		if($data[4] == "0"){
					// 			echo "<li><strong>$data[0]</strong> à <em>$data[2]</em> (<strong>$data[3]</strong>)</li>";
					// 			$cpt++;
					// 		}
					// 	}
					// 	fclose($handle);
					// }
					// if($cpt == 0){echo "Les prochaines dates arrivent !";}
			</ul>
		</div>
	</section-->

	<div class="footer" class="section sc_dark">
		<div class="container">
			<div class="row">
				<div class="col-sm-12 text-center">
					&copy;DéZ’Accordés, designed by <a href="http://www.corentin-drault.fr">corentin drault.</a>
				</div>
			</div>
		</div>
	</div>
	
</body>

<script src="assets/images/https://code.jquery.com/jquery-3.4.1.slim.min.js" integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n" crossorigin="anonymous"></script>
<script src="assets/images/https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossorigin="anonymous"></script>
<script src="assets/images/https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js" integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6" crossorigin="anonymous"></script>

<script type="text/javascript">
document.getElementsByClassName("section")
</script>

</html>
